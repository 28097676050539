<template>
  <div>
    <!-- <x-header :left-options="{backText: '返回'}"
            class="vux-1px-b">
            项目列表
            <a slot="right" @click="onAllSelect">{{isAllSelect ? '全选' : '取消全选'}}</a>
        </x-header>
        <div v-if="loading" class="loading">
            <spinner type="lines" size="30px"></spinner>
        </div> -->
    <div>
      <div v-if="list.length > 0" class="proj-container">
        <div
          class="proj-item"
          v-for="item in list"
          :key="item.id"
        >
          <!-- @click="onDetail(item.id)" -->
          <div class="proj-header">
            <div class="title">
              <img v-if="item.hot" class="hot" src="/static/hot.png" />
              {{ item.name }}
            </div>
            <!-- <div class="fn">
              //<div class="focus-btn">+关注</div>
              <div @click.stop="onSelect(index, item)">
                <check-icon :value.sync="item.isSelect"></check-icon>
              </div>
            </div> -->
          </div>
          <div class="proj-body vux-1px-b">
            <div class="subsidy">
              <div class="sum">
                <span class="num">{{ item.subsidyVal }}</span>
                <span class="unit">{{ item.subsidyUnit }}</span>
              </div>
              <div class="subsidy-tip" @click.stop="onSubsidyTip(item)">
                {{ item.subsidyTitle }}
                <x-icon
                  v-if="item.subsidyTips && item.subsidyTips.length >= 0"
                  class="tip-icon"
                  type="ios-information-outline"
                  size="15"
                  style="fill: #999999"
                ></x-icon>
              </div>
            </div>
            <div class="period-easy">
              <div class="period">
                申报周期 <span>{{ item.period }}</span>
              </div>
              <div class="easy">
                难易程度
                <img
                  v-for="n in item.easy"
                  :key="n"
                  class="star"
                  src="@/assets/star.png"
                />
              </div>
            </div>
          </div>
          <div class="proj-footer">
            <div>
              {{
                item.deadline != null
                  ? item.deadline.substring(0, 10) + "截止"
                  : "--------"
              }}
            </div>
            <div>{{ item.enterpriseNum }}家企业已享受</div>
          </div>
        </div>
      </div>
      <!-- <div v-if="list.length > 0"  class="server" @click="onClickDeal">
                <div class="server-btn">一键办理{{count==0?'':'('+count+')'}}</div>
            </div> -->
      <div v-if="showLoad == false && list.length == 0">
        <img src="/static/nodata.png" class="no-data" />
        <div class="no-data-tips">暂无数据</div>
      </div>
    </div>
    <alert v-model="alertShow" title="错误信息"> {{ alertText }}</alert>
    <!-- <loading :showLoad="showLoad"></loading> -->
  </div>
</template>

<script>
import { TransferDomDirective as TransferDom, Alert } from 'vux'
import { getPolicyProject } from '@/api/policy'
import to from '@/utils/to'
import { mapState } from 'vuex'
// import Loading from './Loading'
export default {
    data() {
        return {
            loading: false,
            isAllSelect: true,
            selectTxt: '全选',
            totalSelect: 0,
            projectIds:[],
            showLoad:false,
            alertShow:false,
            alertText:'',
            list: [],
            count:0
        }
    },
    components: {
        // XHeader,
        // Loading,
        // CheckIcon,
        Alert
    },
    directives: {
        TransferDom
    },
    computed: mapState([
      'projectHash'
    ]),
    methods: {
        onAllSelect() {
            this.list.forEach(element => {
                if (this.isAllSelect)
                    element.isSelect = true;
                else
                    element.isSelect = false;
            });
            if(this.isAllSelect){
              this.count=this.list.length;
              this.isAllSelect=false;
            }
            else{
              this.count=0;
              this.isAllSelect=true;
            }
        },
        onSelect(index,item) {
          if(item.isSelect){
            this.count++
          }
          else{
            this.count--;
          }
          this.$set(this.list,index,item);
        },
        onDetail(id) {
          let p = this.projectHash.get(id);
          this.$router.push({path: p.url,
            query: {
              dId: p.documentId
            }})
        },
        onSubsidyTip(item) {
            console.log(item.subsidyTips)
        },
        onClickDeal() {
          console.log('projectIds:' + this.projectIds)
          this.$router.push({path: '/server',
            query: {
              projectId: this.projectIds,
              province: '重庆市'
            }})
        }
    },
    async mounted() {
      this.showLoad=true
      this.projectIds = this.$route.query.id;
      if (this.projectIds == undefined) {
        this.projectIds = []
        console.log(this.projectIds)
      }

        this.$showLoading('加载中...')
        let [err, res] = await to(getPolicyProject({ids: this.projectIds}))
        this.$hideLoading()
        if (err) {
            this.$showToast('加载失败')
            return
        }

        this.list= res;
         this.list.forEach(element => {
           element.isSelect = false
        });

    //   this.$http.get('/api/v1.0/project', {
    //     params: {
    //       'ids': this.projectIds
    //     }
    //   }).then(({data}) => {
    //      this.showLoad=false;
    //      this.list=data.rows;
    //      this.list.forEach(element => {
    //        element.isSelect = false
    //     });
    //   })
    //     .catch((err) => {
    //       this.showLoad=false;
    //       this.alertText = err.response.data.message
    //       this.alertShow = true;
    //     })
    }
}
</script>

<style scoped lang="less">
// @import "~vux/src/styles/1px.less";
// @import "../style/noData.css";

.proj-container {
  padding: 0 15px 60px 15px;
}

.proj-item {
  font-size: 14px;
  color: #999999;
  margin-top: 10px;
  background-color: #ffffff;
  border-radius: 5px;
  padding: 10px 19px;
}

.proj-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.title {
  font-size: 20px;
  color: #333333;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 10px;
}

.hot {
  width: 17px;
  height: 20px;
}

.fn {
  display: flex;
}

.focus-btn {
  color: #ed4d31;
  letter-spacing: 2px;
  border-radius: 5px;
  padding: 0 4px;
  margin-right: 13px;
  border: solid 1px #ed4d31;
}

.proj-body {
  display: flex;
  justify-content: space-between;
  padding: 12px 4px 12px 4px;
}

.num {
  font-size: 30px;
  color: #ff0505;
}

.unit {
  font-size: 15px;
  color: #ff0505;
}

.tip-icon {
  position: relative;
  top: 3px;
  left: -2px;
}

.period {
  margin-top: 10px;
  margin-bottom: 8px;
}

.subsidy-tip {
  margin-top: -5px;
}

.period span {
  font-size: 16px;
  color: #333333;
  padding-left: 4px;
}

.easy img {
  height: 15px;
  width: 15px;
  padding-left: 4px;
  position: relative;
  top: 2px;
}

.proj-footer {
  display: flex;
  justify-content: space-between;
  padding-top: 10px;
}

.server {
  display: flex;
  background-color: #f1f1f1;
  box-sizing: border-box;
  padding: 5px 10px;
  position: fixed;
  width: 100%;
  height: 50px;
  bottom: 0;
  box-shadow: 0px -1px 5px #dadada;
}

.server-btn {
  flex-grow: 2;
  color: #ffffff;
  text-align: center;
  font-size: 18px;
  line-height: 40px;
  background-color: #108eeb;
  border-radius: 5px;
}
</style>

<style>
.vux-check-icon > .weui-icon-success:before,
.vux-check-icon > .weui-icon-success-circle:before {
  color: #108eeb !important;
}
</style>
